import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Tag,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { titleSize, get } from "src/api";
import { FormattedMessage, useIntl } from "react-intl";
import { FaVideo } from "react-icons/fa6";
import TutorialModal from "../TutorialModal/TutorialModal";

interface SectionHeaderProps {
  slug: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ slug }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  const { locale } = useIntl();

  useEffect(() => {
    get(`/video-tutorial/${slug}`, locale)
      .then((res: any) => {
        setVideoUrl(res.url || null);
      })
      .catch((err: any) => {
        console.error(err);
        setVideoUrl(null);
      });
  }, [slug, locale]);

  return (
    <Box py={2}>
      <Box d="flex" alignItems="end" gap={2}>
        <Box>
          <Text
            as="h2"
            textStyle={"h2"}
            fontSize={titleSize}
            fontWeight="bold"
            color="#1f3f93"
            fontFamily={"Calibri (Body)"}
          >
            <FormattedMessage id={slug} />
          </Text>
          {videoUrl && (
            <Tag
              backgroundColor="#EFEFEF"
              cursor="pointer"
              onClick={onOpen}
              _hover={{
                backgroundColor: "#E0E0E0",
                transform: "scale(1.05)",
                transition: "all 0.2s ease",
              }}
            >
              <Icon as={FaVideo} marginRight="2" color="#1f3f93" />
              <Text color="#1f3f93">
                <FormattedMessage id="video_tutorial" />
              </Text>
            </Tag>
          )}
        </Box>
      </Box>

      <TutorialModal isOpen={isOpen} onClose={onClose} videoUrl={videoUrl} />
    </Box>
  );
};

export default SectionHeader;
