import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";
import {
  Box,
  Link,
  Text,
  Tooltip,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { useAppContext } from "src/Providers";
import { useDispatch, useSelector } from "src/store";
import { calibrationFunc as calibrationFuncAction } from "src/store/calibration";
import { put, get } from "src/api";
import { BsQuestion } from "react-icons/bs";
import TutorialModal from "src/components/TutorialModal/TutorialModal";

const clearNotification = async (sectionName) => {
  return await put(`/shared/notification/${sectionName}`, { seen: true });
};

const Category = ({ category, notifications }) => {
  const { locale, formatMessage } = useIntl();
  const context = useAppContext();
  const dispatch = useDispatch();
  const calibrationFunc = () => dispatch(calibrationFuncAction());
  const doneCalibrating = useSelector((state) => state.calibration.done);
  const location = useLocation();
  const condition =
    (!doneCalibrating && category.name === "sendingintention") ||
    (!doneCalibrating && category.name === "subliminal");
  const isActive = location.pathname.includes(category.route);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [slug, setSlug] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  const [showTutorial, setShowTutorial] = useState<boolean>(false);
  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth >= 768);
  const [hasVideo, setHasVideo] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (category.name) {
      get(`/video-tutorial/${category.name}`, locale).then((res: any) => {
        setHasVideo(!!res?.url);
      });
    }
  }, [category.name, locale]);

  useEffect(() => {
    get(`/video-tutorial/${slug}`, locale)
      .then((res: any) => {
        setVideoUrl(res.url || null);
      })
      .catch((err: any) => {
        console.error(err);
        setVideoUrl(null);
      });
  }, [slug, locale]);

  return (
    <li>
      <Link
        onClick={() => {
          clearNotification(category.name).finally(() => {
            if (condition) {
              calibrationFunc();
            }
          });
        }}
        transition="none"
        as={condition || category.upcoming ? "button" : ReactRouterLink}
        width={condition || category.upcoming ? "100%" : "auto"}
        to={condition || category.upcoming ? `#` : category.route}
        _activeLink={{ color: "white" }}
        _focus={{ outline: "none", border: "none" }}
        _hover={{ textDecoration: "none" }}
        aria-current={isActive ? "page" : "false"}
        onMouseEnter={() => isDesktop && setShowTutorial(true)}
        onMouseLeave={() => isDesktop && setShowTutorial(false)}
      >
        <Box
          style={{
            position: "relative",
            overflow: "visible", // Set overflow to "visible" for notifications
          }}
        >
          <Text
            as={"div"}
            d="flex"
            justifyContent="space-around"
            alignItems="center"
            textAlign="center"
            px={3}
            py={2.5}
            fontWeight="normal"
            style={{
              color: "black",
              // padding: "0.5em",
              backgroundColor: category.upcoming ? "silver" : "#f9f9f9",
              borderRadius: "8px",
              boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
              marginTop: "0.8em",
              marginBottom: "0em",
              fontFamily: "Calibri (Body)",
              fontSize: "18px",
              letterSpacing: "normal",
              fontStyle: "normal",
              position: "relative",
              overflow: "hidden", // Set overflow to "hidden" for the new banner
              cursor: category.upcoming ? "default" : "pointer",
            }}
            onClick={() => !condition && context.background?.set("#fff")}
          >
            <Text flex={1} textAlign="center">
              {formatMessage({
                id: category.name,
              })}
            </Text>

            <Box
              d="flex"
              justifyContent="center"
              alignItems="center"
              visibility={
                hasVideo
                  ? isDesktop
                    ? showTutorial
                      ? "visible"
                      : "hidden"
                    : "visible"
                  : "hidden"
              }
              opacity={hasVideo ? (isDesktop ? (showTutorial ? 1 : 0) : 1) : 0}
              transition="opacity 0.3s ease-in-out"
              position="absolute"
              right={3}
            >
              <Icon
                as={BsQuestion}
                color="#1f3f93"
                aria-label="Tutorial"
                fontSize="1.4em"
                textShadow="0px 2px 4px rgba(0, 0, 0, 0.4)"
                filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4))"
                backgroundColor="white"
                borderRadius="50%"
                transform={locale === "ar" ? "scaleX(-1)" : "none"}
                onClick={(e) => {
                  if (isDesktop && !showTutorial) return;
                  e.preventDefault();
                  e.stopPropagation();
                  setSlug(category.name);
                  onOpen();
                }}
              />
            </Box>

            {/* Content of the new banner */}
            {category.upcoming && (
              <Box
                position="absolute"
                left="-30px"
                top="2px"
                bg="brand.800"
                color="white"
                padding="5px"
                width="100px"
                transform={`rotate(${locale === "ar" ? "" : "-"}45deg)`}
              >
                <Text as={"div"}>
                  {formatMessage({
                    id: "soon",
                  })}
                </Text>
              </Box>
            )}
            {category.new && (
              <Box
                position="absolute"
                left="-30px"
                top="1.5"
                bg="brand.800"
                color="white"
                width="100px"
                transform={`rotate(${locale === "ar" ? "" : "-"}45deg)`}
              >
                <Text as={"div"}>
                  {formatMessage({
                    id: "new",
                  })}
                </Text>
              </Box>
            )}
          </Text>

          {/* Notifications */}
          {notifications && (
            <Tooltip
              label={`${formatMessage({
                id: "youHave",
              })} ${
                locale === "en" || notifications.count > 2
                  ? notifications.count
                  : ""
              } ${formatMessage({
                id:
                  notifications.count < 3 && locale === "ar"
                    ? `newSharedItems${notifications.count}`
                    : `newSharedItems3`,
              })}`}
              placement="top"
            >
              <Box
                position="absolute"
                right="-8px"
                top="-8px"
                bg="red.500"
                color="white"
                padding="5px"
                width="25px"
                height="25px"
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {notifications.count}
              </Box>
            </Tooltip>
          )}

          <TutorialModal
            isOpen={isOpen}
            onClose={onClose}
            videoUrl={videoUrl}
          />
        </Box>
      </Link>
    </li>
  );
};

export default Category;
