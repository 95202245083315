import React, { useMemo, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  useDisclosure,
  IconButton,
  useMediaQuery,
} from "@chakra-ui/react";
import { HiPlay, HiPause } from "react-icons/hi";
import { BsInfoCircle } from "react-icons/bs";
import { useIntl } from "react-intl";
import { usePlayback } from "./PlaybackContext";
import StaticRatingStars from "src/components/Rating/StaticRatingStars";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PureToneInfoModal from "../PureToneInfoModal";
import { useDispatch } from "src/store";
import OtherSourcesInfoModal from "../OtherSourcesInfoModal";
import RatingModalV2, { MyRating } from "src/components/Rating/RatingModalV2";
import { FaRepeat } from "react-icons/fa6";
import { toggleTrackLooping } from "src/store/playback";
import Waveform from "./Waveform";
import { MdPlaylistAdd } from "react-icons/md";
import AddToPlaylistModal from "src/components/AddToPlaylistModal";
import { OtherSource, PureTone } from "src/types";

interface WaveformPlayerProps {
  entityName: string;
  trackId: number;
  trackName: string;
  trackCategory: string;
  trackCover: string;
  trackUrl: string;
  trackInfo: string;
  trackRating?: number;
  currentUserRating?: MyRating | undefined;
  refetchTracks?: () => void;
  categoryId: string;
  preloadedDuration: number;
}

const SalMagicAudioPlayer: React.FC<WaveformPlayerProps> = ({
  entityName,
  trackUrl,
  trackName,
  trackCategory,
  trackInfo,
  trackId,
  trackCover,
  trackRating,
  currentUserRating,
  refetchTracks,
  categoryId,
  preloadedDuration,
}) => {
  const [currentTrack, setCurrentTrack] = useState<any>(null);

  const {
    audioRef,
    currentTrackUrl,
    currentTime,
    togglePlayPause,
    isPlaying,
    isLooping,
  } = usePlayback();

  const [isMobile] = useMediaQuery("(max-width: 465px)");

  const { locale } = useIntl();
  const {
    isOpen: isInfoModalOpen,
    onOpen: onInfoModalOpen,
    onClose: onInfoModalClose,
  } = useDisclosure();
  const {
    isOpen: isRatingModalOpen,
    onOpen: onRatingModalOpen,
    onClose: onRatingModalClose,
  } = useDisclosure();
  const {
    isOpen: isAddPureTonesToPlaylistModalOpen,
    onOpen: onAddPureToneToPlaylistModalOpen,
    onClose: onAddPureTonesToPlaylistModalClose,
  } = useDisclosure();

  const {
    isOpen: isAddOtherSourcesToPlaylistModalOpen,
    onOpen: onAddOtherSourcesToPlaylistModalOpen,
    onClose: onAddOtherSourcesToPlaylistModalClose,
  } = useDisclosure();

  const formatTime = (seconds: number) =>
    [seconds / 60, seconds % 60]
      .map((v) => `0${Math.floor(v)}`.slice(-2))
      .join(":");

  const playPauseIcon = useMemo(() => {
    return isPlaying && currentTrackUrl === trackUrl ? (
      <HiPause
        size={"3rem"}
        color={"#2895ff"}
        style={{ margin: "0 5px" }}
        cursor="pointer"
      />
    ) : (
      <HiPlay
        size={"3rem"}
        color={"#2895ff"}
        style={{ margin: "0 5px" }}
        cursor="pointer"
      />
    );
  }, [isPlaying, currentTrackUrl, trackUrl]);

  const handlePlayPause = () => {
    togglePlayPause({
      url: trackUrl,
      categoryId,
    });
  };

  const dispatch = useDispatch();
  const toggleTrackLoop = () => dispatch(toggleTrackLooping());

  useEffect(() => {
    setTimeout(() => {
      const categoryElement = document.getElementById(
        `category-${
          isPlaying && currentTrackUrl === trackUrl ? categoryId : ""
        }`
      );
      if (categoryElement) {
        if (categoryElement) {
          categoryElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          categoryElement.focus();
          const accordionButton = categoryElement.querySelector(
            ".chakra-accordion__button"
          ) as HTMLButtonElement;
          if (
            accordionButton &&
            accordionButton.getAttribute("aria-expanded") === "false"
          ) {
            accordionButton.click();
          }

          setTimeout(() => {
            const element = document.getElementById(
              isPlaying && currentTrackUrl === trackUrl
                ? `magic-${trackUrl}`
                : ""
            );
            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "center" });
              element.focus();
            }
          }, 300);
        }
      }
    }, 500);
  }, []);

  return (
    // <span id={`magi-${trackUrl}`} style={{ width: "100%" }}>
    <Flex
      id={`magic-${trackUrl}`}
      alignItems="center"
      justifyContent="space-between"
      // gap={5}
      // position="relative"
      // bgColor="gray"
      px={{ base: 0, md: 50 }}
      w={"100%"}
    >
      <Flex alignItems={"center"} gap={5}>
        <Flex flexDir="column" mt={10}>
          <Box
            // position="relative"
            backgroundImage={trackCover ?? "/gallery.png"}
            backgroundSize="cover"
            borderRadius={10}
            boxSize={100}
            // mr={3}
          >
            <Flex
              position="relative"
              borderRadius={10}
              boxSize={100}
              // justifyContent="center"
              alignItems="center"
              backgroundColor={trackCover ? "blackAlpha.400" : "transparent"}
            >
              <Box
                position="absolute"
                backgroundColor="white"
                boxSize="45px"
                boxSizing="border-box"
                border="solid 20px white"
                right={0}
                transform="translateX(50%)"
                borderRadius="50%"
              />
              <IconButton
                position="absolute"
                right={0}
                transform="translateX(50%)"
                onClick={handlePlayPause}
                aria-label={"Play/Pause"}
                icon={playPauseIcon}
                backgroundColor="transparent"
                _hover={{ backgroundColor: "transparent" }}
                _focusWithin={{ backgroundColor: "transparent" }}
                // disabled={!isPlaying && currentTrackUrl !== trackUrl}
              />
            </Flex>
          </Box>
          {entityName === "pure_tones" && (
            <Flex alignItems="center" justifyContent="center">
              <StaticRatingStars rating={trackRating} starSize={16} />
              <IconButton
                variant="button"
                color="gold"
                aria-label="add"
                onClick={onRatingModalOpen}
                icon={<AddCircleOutlineIcon />}
              />
            </Flex>
          )}
        </Flex>

        <Box>
          <Text
            fontSize="1.3rem"
            width={isMobile ? "100%" : "100%"}
            style={{ minWidth: isMobile ? "90px" : "" }}
          >
            {trackName}
          </Text>
          {isMobile && (
            <Flex gap={1} alignItems={"center"} mt={2}>
              <span>
                {locale === "en"
                  ? formatTime(currentTrackUrl === trackUrl ? currentTime : 0)
                  : formatTime(preloadedDuration)}
              </span>
              <span>/</span>
              <span>
                {locale === "en"
                  ? formatTime(preloadedDuration)
                  : formatTime(currentTrackUrl === trackUrl ? currentTime : 0)}
              </span>
            </Flex>
          )}
        </Box>
      </Flex>

      {isMobile && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
          }}
        >
          <IconButton
            bgColor="transparent"
            _hover={{ backgroundColor: "transparent" }}
            _focusWithin={{ backgroundColor: "transparent" }}
            aria-label="Repeat"
            disabled={currentTrackUrl !== trackUrl}
            onClick={toggleTrackLoop}
            marginX={2}
            icon={
              <FaRepeat
                color={
                  isLooping && currentTrackUrl === trackUrl
                    ? "#2895ff"
                    : "#808080"
                }
              />
            }
          />
        </div>
      )}

      <Flex alignItems={"center"} w={"60%"}>
        <span
          style={
            isMobile
              ? { display: "none" }
              : {
                  width: "100%",
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }
          }
        >
          <IconButton
            bgColor="transparent"
            _hover={{ backgroundColor: "transparent" }}
            _focusWithin={{ backgroundColor: "transparent" }}
            aria-label="Repeat"
            disabled={currentTrackUrl !== trackUrl}
            onClick={toggleTrackLoop}
            marginX={2}
            icon={
              <FaRepeat
                color={
                  isLooping && currentTrackUrl === trackUrl
                    ? "#2895ff"
                    : "#808080"
                }
              />
            }
          />
          <span>
            {locale === "en"
              ? formatTime(currentTrackUrl === trackUrl ? currentTime : 0)
              : formatTime(preloadedDuration)}
          </span>

          <Waveform
            play={isPlaying && audioRef.current?.currentSrc === trackUrl}
          />

          <span>
            {locale === "en"
              ? formatTime(preloadedDuration)
              : formatTime(currentTrackUrl === trackUrl ? currentTime : 0)}
          </span>
        </span>

        <IconButton
          aria-label="Info"
          icon={<BsInfoCircle size={"1rem"} cursor={"pointer"} color="gray" />}
          backgroundColor="transparent"
          _hover={{ backgroundColor: "transparent" }}
          onClick={onInfoModalOpen}
        />
        {entityName === "pure_tones" ? (
          <IconButton
            // color="#e97575"
            onClick={(e: any) => {
              e.stopPropagation();
              setCurrentTrack({
                id: trackId,
                musicFreeUrl: trackUrl,
                name: trackName,
                trackType: "musicFreeUrl",
              });
              onAddPureToneToPlaylistModalOpen();
            }}
            variant="link"
            aria-label="delete"
            fontSize="23px"
            icon={<MdPlaylistAdd />}
            h="5"
          />
        ) : (
          <IconButton
            // color="#e97575"
            onClick={(e: any) => {
              e.stopPropagation();
              setCurrentTrack({
                id: trackId,
                url: trackUrl,
                name: trackName,
                // trackType: "musicFreeUrl",
              });
              onAddOtherSourcesToPlaylistModalOpen();
            }}
            variant="link"
            aria-label="delete"
            fontSize="23px"
            icon={<MdPlaylistAdd />}
            h="5"
          />
        )}
      </Flex>
      {entityName === "pure_tones" ? (
        <>
          <PureToneInfoModal
            isOpen={isInfoModalOpen}
            onClose={onInfoModalClose}
            id={trackId}
            title={trackName}
            category={trackCategory}
            duration={formatTime(preloadedDuration)}
            description={trackInfo}
            refetch={refetchTracks ?? (() => {})}
          />
          {isRatingModalOpen && (
            <RatingModalV2
              isOpen={isRatingModalOpen}
              onClose={onRatingModalClose}
              entityId={trackId}
              entityName={"pure_tones"}
              refetchRating={refetchTracks ?? (() => {})}
              currentUserRating={currentUserRating}
            />
          )}
          {isAddPureTonesToPlaylistModalOpen && (
            <AddToPlaylistModal
              isOpen={isAddPureTonesToPlaylistModalOpen}
              onClose={onAddPureTonesToPlaylistModalClose}
              track={makeTrackFromPureTone(currentTrack)}
              entityName={"pureTones"}
            />
          )}
        </>
      ) : (
        <>
          <OtherSourcesInfoModal
            isOpen={isInfoModalOpen}
            onClose={onInfoModalClose}
            id={trackId}
            title={trackName}
            category={trackCategory}
            duration={formatTime(preloadedDuration)}
            description={trackInfo}
          />
          {isAddOtherSourcesToPlaylistModalOpen && (
            <AddToPlaylistModal
              isOpen={isAddOtherSourcesToPlaylistModalOpen}
              onClose={onAddOtherSourcesToPlaylistModalClose}
              track={makeTrackFromOtherSources(currentTrack)}
              entityName={"otherSources"}
            />
          )}
        </>
      )}
    </Flex>

    // </span>
  );
};

export default SalMagicAudioPlayer;
const makeTrackFromPureTone = (pure_tone: PureTone | undefined) => {
  if (!pure_tone) return;
  return {
    name: pure_tone.name,
    audioUrl: pure_tone.url,
    entityName: "pure_tones",
    entityId: pure_tone.id,
  };
};

const makeTrackFromOtherSources = (other_source: OtherSource | undefined) => {
  if (!other_source) return;
  return {
    name: other_source.name,
    audioUrl: other_source.url,
    entityName: "other_sources",
    entityId: other_source.id,
  };
};
