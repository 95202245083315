import React, { useState, useEffect } from "react";
import {
  Text,
  useDisclosure,
  Button,
  Box,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import DescriptionModal from "./DescriptionModal";
import { get } from "src/api";
import { FormattedMessage, useIntl } from "react-intl";
import CalibrationModal from "../Affirmation/Calibration/CalibrationModal";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { useDispatch } from "src/store";
import { calibrationFunc as calibrationFuncAction } from "src/store/calibration";
import Category from "./Category";
import { BsQuestion } from "react-icons/bs";
import TutorialModal from "src/components/TutorialModal/TutorialModal";

type SectionProps = {
  categories: {
    route?: string;
    name: string;
  }[];
  slug: string;
  title: string;
  mainRoute: string;
  notifications: any;
};

const Section: React.FC<SectionProps> = ({
  categories,
  title,
  slug,
  mainRoute,
  notifications,
}) => {
  const { push } = useHistory();
  const { isOpen, onClose } = useDisclosure();
  const finalRef = React.useRef();
  const [content, setContent] = useState("");
  const { locale } = useIntl();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const calibrationFunc = () => dispatch(calibrationFuncAction());
  useEffect(() => {
    get<any>("/description/" + slug.toLowerCase()).then((res) => {
      setContent(res?.content);
    });
  }, [locale, slug]);

  const {
    isOpen: isTutorialOpen,
    onOpen: onTutorialOpen,
    onClose: onTutorialClose,
  } = useDisclosure();

  const [showTutorial, setShowTutorial] = useState(false);
  const [tutorialUrl, setTutorialUrl] = useState<string | null>(null);

  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box my={2} mx={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        backgroundColor="#1f3f93"
        borderRadius="12px"
        boxShadow="0px 3px 6px 0px rgba(0, 0, 0, 0.35)"
        py={2}
        px={4}
        w={"16rem"}
        onMouseEnter={() => isDesktop && setShowTutorial(true)}
        onMouseLeave={() => isDesktop && setShowTutorial(false)}
        position="relative"
      >
        <Button
          flex={1}
          bgColor="transparent"
          _focus={{}}
          _hover={{}}
          _active={{}}
          onClick={() => {
            push(mainRoute);
          }}
        >
          <Text
            textAlign="center"
            fontWeight="normal"
            style={{
              color: "white",
              fontSize: "18pt",
              fontFamily: "Calibri (Body)",
            }}
          >
            <FormattedMessage id={title} />
          </Text>
        </Button>
        {/* <Icon
          key={`${title}-tutorial-icon`}
          as={BsQuestion}
          visibility={
            isDesktop ? (showTutorial ? "visible" : "hidden") : "visible"
          }
          opacity={isDesktop ? (showTutorial ? 1 : 0) : 1}
          transition="opacity 0.3s ease-in-out"
          position="absolute"
          right={3}
          color="white"
          aria-label="Tutorial"
          fontSize="1.6em"
          borderRadius="50%"
          backgroundColor="#1f3f93"
          cursor="pointer"
          textShadow="0px 2px 4px rgba(0, 0, 0, 0.4)"
          filter="drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4))"
          transform={locale === "ar" ? "scaleX(-1)" : "none"}
          onClick={(e) => {
            if (isDesktop && !showTutorial) return;
            e.stopPropagation();
            // Call the API to get the tutorial URL based on title
            get<any>(`/video-tutorial/${title}`)
              .then((res) => {
                setTutorialUrl(res?.url || null);
                onTutorialOpen();
              })
              .catch((error) => {
                console.error("Error fetching tutorial URL", error);
                setTutorialUrl(null);
              });
          }}
        /> */}
      </Box>

      <DescriptionModal
        finalRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        slug={slug}
        content={content}
      />
      <ul
        style={{
          listStyleType: "none",
          padding: "0",
          margin: "0",
        }}
      >
        {categories?.map((category) => {
          return (
            <Category
              key={category.route}
              category={category}
              notifications={
                notifications ? notifications[category.name] : null
              }
            />
          );
        })}
      </ul>
      <CalibrationModal
        children={
          <Button
            onClick={() => {
              calibrationFunc && calibrationFunc();
              setModalOpen(false);
            }}
            color={"white"}
            width={localStorage.getItem("locale") === "ar" ? "168px" : "176px"}
            height={"40px"}
            background={"#2895ff"}
            fontFamily={"tajwal"}
            fontSize={"16px"}
            fontWeight={"normal"}
            mt={"20px"}
            rightIcon={
              <AiOutlinePlayCircle
                fontSize={"24px"}
                style={
                  localStorage.getItem("locale") === "ar"
                    ? { marginRight: "1rem" }
                    : { marginLeft: "1px" }
                }
              />
            }
          >
            <FormattedMessage
              id="calibration.start"
              defaultMessage={"بدء المعايرة"}
            />
          </Button>
        }
        open={modalOpen}
        modalClose={() => setModalOpen(false)}
      />

      <TutorialModal
        isOpen={isTutorialOpen}
        onClose={onTutorialClose}
        videoUrl={tutorialUrl}
      />
    </Box>
  );
};

export default Section;
