import React from "react";
import { useBreakpointValue } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  IconButton,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

interface TutorialModalProps {
  isOpen: boolean;
  onClose: () => void;
  videoUrl: string | null;
}

const TutorialModal = ({ isOpen, onClose, videoUrl }: TutorialModalProps) => {
  const isDesktop = useBreakpointValue({ base: false, md: true });

  if (!videoUrl) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={isDesktop ? "full" : "4xl"}
      isCentered
    >
      <ModalOverlay />
      <ModalContent bg="transparent" boxShadow="none">
        <ModalBody p={0}>
          <Box
            p={isDesktop ? 20 : 0}
            bgColor="transparent"
            borderRadius="md"
            height={isDesktop ? "auto" : "80vh"}
            width="100%"
            position="relative"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              aria-label="Close modal"
              icon={<CloseIcon />}
              onClick={onClose}
              isRound
              size="md"
              colorScheme="gray"
              bg="white"
              position="fixed"
              top={isDesktop ? "10%" : "3%"}
              right={isDesktop ? "8%" : "5%"}
              zIndex={10}
              boxShadow="md"
            />
            <Box
              as="iframe"
              width={isDesktop ? "80%" : "100%"}
              height={isDesktop ? "80vh" : "100%"}
              src={`${videoUrl}${
                videoUrl.includes("?") ? "&" : "?"
              }playsinline=1&rel=0&fs=1`}
              allowFullScreen
              allow="autoplay; fullscreen"
              borderRadius="md"
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TutorialModal;
